import { createSlice } from '@reduxjs/toolkit'
import { del } from 'src/utils/requests'
import { API_PRODUCTS } from 'src/api'
import { message } from 'antd'

const initialState = {
  name: '',
  intername: '',
  product_code: '',
  barcode: '',
  tariff_number: null,
  tariff_number_label: null,
  category: null,
  category_label: null,
  price: null,
  quantity: null,
  currency: null,
  description: '',
  for_sale: false,
  is_service: false,
  minimum_order_quantity: null,
  weight: '',
  volume: null,
  length: null,
  width: null,
  height: null,
  unit: 'PCS',
  measurement: 'MS',
  image: null,
  imageSource: null,
  errors: {},
}

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setValue(state, action) {
      Object.keys(action.payload).forEach((key) => {
        if (key in state.errors) {
          delete state.errors[key as keyof typeof state.errors]
        }
      })
      Object.assign(state, action.payload)
    },
    reset(state, action) {
      return { ...initialState }
    },
    deleteItem(state, action) {
      const { id } = action.payload
      del(`${API_PRODUCTS}${id}/`)
        .then((res: any) => {
          message.success(JSON.stringify(res?.statusText))
          // navigate('/products/')
          window.location.href = '/products/'
        })
        .catch((err: any) => {
          // setAlert(true)
        })
    },
  },
})

export const { setValue, reset, deleteItem } = productSlice.actions
export default productSlice.reducer
